<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">10. Транспортная доступность</span>
             <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <div class="rating-popup">
      <v-container>        


          <v-row>
            <v-col sm="6">
              1.	Офис/точка присутствия находится в пешей доступности от остановок общественного транспорта (не более 15 минут)
            </v-col>
            <v-col sm="6">
              <div>
                <v-file-input
                label="Прикрепить документ"
                outlined
                dense
              ></v-file-input>
              </div>
              <div>
                <v-text-field outlined dense label="Комментарий"></v-text-field>
              </div>
              <div>
                <v-checkbox label="Перенести подтверждающие документы предыдущего отчетного периода" ></v-checkbox>  
              </div>
            </v-col>
          </v-row>

           <v-row>
            <v-col sm="6">
              2.	Наличие навигации к ЦЗН на остановках общественного транспорта/в значимых общественных местах
            </v-col>
            <v-col sm="6">
              <div>
                <v-file-input
                label="Прикрепить документ"
                outlined
                dense
              ></v-file-input>
              </div>
              <div>
                <v-text-field outlined dense label="Комментарий"></v-text-field>
              </div>
              <div>
                <v-checkbox label="Перенести подтверждающие документы предыдущего отчетного периода" ></v-checkbox>  
              </div>
            </v-col>
          </v-row>

           <v-row>
            <v-col sm="6">
             3.	Наличие доступной парковки на территории ЦЗН
            </v-col>
            <v-col sm="6">
              <div>
                <v-file-input
                label="Прикрепить документ"
                outlined
                dense
              ></v-file-input>
              </div>
              <div>
                <v-text-field outlined dense label="Комментарий"></v-text-field>
              </div>
              <div>
                <v-checkbox label="Перенести подтверждающие документы предыдущего отчетного периода" ></v-checkbox>  
              </div>
            </v-col>
          </v-row>




      </v-container>

     
     </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        отмена
      </v-btn>
      <v-btn      
         class="btn btn-primary"
          @click="$emit('close')"
      >
        Сохранить изменения
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'DataDialog',

    data: () => ({
    }),
  }
</script>
