<template>
  <v-card>
    <v-card-title>
      <div class="text-h5 text-center" style="width: 100%">Экспорт загруженных<br/>объективных данных</div>
             <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <div class="rating-popup">
      <v-container>        


          <v-row class="mb-48">
            <v-col cols="12">
              <h4>Формат</h4>
              <v-radio-group                  
                  row
              >
                <v-radio
                    label="PDF"
                    value="PDF"
                ></v-radio>
                <v-radio
                    label="XLSX"
                    value="XLSX"
                ></v-radio>
              </v-radio-group>

            </v-col>
          </v-row>

         
         <v-row>
             <v-col cols="12">
              <h4>Фильтры</h4>
              </v-col>

              <v-col
              cols="12"
              sm="12"
          >
            <v-select
                :items="['1', '2', '3', '4']"
                label="Показатели"
            ></v-select>
          </v-col>

         </v-row>

        <v-row>
            <v-col sm="12">
                        <v-menu
                            min-width="auto"
                            offset-y
                            transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field                                
                                v-bind="attrs"
                                v-on="on"
                                append-icon="mdi-calendar"
                                label="Период сбора информации"
                                readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="date"
                              no-title
                              scrollable
                              locale="RU"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
        </v-row>




      </v-container>

     
     </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        Закрыть
      </v-btn>
      <v-btn      
         class="btn btn-primary"
          @click="$emit('close')"
      >
        Скачать
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'ExportDataDialog',

    data: () => ({
    }),
  }
</script>


